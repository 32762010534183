<template>
	<div class="page-container">
		<nav-bar title="已推送学时及审核结果查询" id="navbar" left-arrow @click-left="onClickLeft"></nav-bar>
		<van-tabs v-model="activeName" @click="onClickTab">
			<van-tab title="科目一" name="1">
				<van-cell-group inset title="科目一审核学时记录" v-if="part1.classRecord.length > 0">
					<template>
						<div style="margin: 10px 0;">
							<van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" wrapable :scrollable="false">
								审核后总计有效学时不足时，请在开始学习页面点击"学时不够，请点这里"，继续学习补齐学时，系统方可自动提交该阶段学时。
							</van-notice-bar>
							<van-row style="border-bottom: 1px #ccc solid;">
								<van-col span="14">签到/签退时间</van-col>
								<van-col span="4">训美学时</van-col>
								<van-col span="4">审核学时</van-col>
								<van-col span="2"></van-col>
							</van-row>
							<van-row v-for="(item, index) in part1.classRecord" :key="index" style="border-bottom: 1px #eee solid;">
								<van-col span="14">{{ item.starttime }}~{{ item.endtime }}</van-col>
								<van-col span="4">{{ item.submitDuration }}</van-col>
								<van-col span="4">{{ item.validDuration }}</van-col>
								<van-col span="2"><van-icon name="question-o" color="#ee0a24" v-if="item.deductionreson !== ''" @click="showMsg(item)" /></van-col>
							</van-row>
							<van-row style="border-top: 1px #ccc solid;">
								<van-col span="14">学时合计</van-col>
								<van-col span="4"><span style="color:#888; font-weight: bold;">{{ part1.xmTotalPeriod }}</span></van-col>
								<van-col span="4"><span style="color:#E10900; font-weight: bold;">{{ part1.validTotalPeriod }}</span></van-col>
								<van-col span="2"></van-col>
							</van-row>
						</div>
					</template>
				</van-cell-group>
				<van-empty v-if="part1.classRecord.length === 0" description="您还没有科目一电子教学日志推送记录" />
			</van-tab>
			<van-tab title="科目四" name="4">
				<van-cell-group inset title="科目四审核学时记录" v-if="part4.classRecord.length > 0">
					<template>
						<div style="margin: 10px 0;">
							<van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" wrapable :scrollable="false">
								审核后总计有效学时不够的，请在开始学习页面点击"学时不够，请点这里"，继续学习补齐学时，系统方可自动提交该阶段学时。
							</van-notice-bar>
							<van-row style="border-bottom: 1px #ccc solid;">
								<van-col span="14">签到/签退时间</van-col>
								<van-col span="4">训美学时</van-col>
								<van-col span="4">审核学时</van-col>
								<van-col span="2"></van-col>
							</van-row>
							<van-row v-for="(item, index) in part4.classRecord" :key="index" style="border-bottom: 1px #eee solid;">
								<van-col span="14">{{ item.starttime }}~{{ item.endtime }}</van-col>
								<van-col span="4">{{ item.submitDuration }}</van-col>
								<van-col span="4">{{ item.validDuration }}</van-col>
								<van-col span="2"><van-icon name="question-o" color="#ee0a24" v-if="item.deductionreson !== ''" @click="showMsg(item)" /></van-col>
							</van-row>
							<van-row style="border-top: 1px #ccc solid;">
								<van-col span="14">学时合计</van-col>
								<van-col span="4"><span style="color:#888; font-weight: bold;">{{ part4.xmTotalPeriod }}</span></van-col>
								<van-col span="4"><span style="color:#E10900; font-weight: bold;">{{ part4.validTotalPeriod }}</span></van-col>
								<van-col span="2"></van-col>
							</van-row>
						</div>
					</template>
				</van-cell-group>
				<van-empty v-if="part4.classRecord.length === 0" description="您还没有科目四电子教学日志推送记录" />
			</van-tab>
		</van-tabs>
		<!-- loading 遮罩层 -->
		<van-overlay :show="showLoading" color="#1989fa">
			<div class="wrapper">
				<div class="block">
					<van-loading size="24px" vertical>
						<span style="color:#ff8506">{{ title || '加载中...' }}</span>
					</van-loading>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
import { NavBar, Toast } from "vant";
import { getUploadedPeriodInfoGD } from "@/api/user";

export default {
	name: "period-index",
	components: { NavBar },
	data() {
		return {
			title: '',
			showLoading: false,
			activeName: '',
			part1: {
				pushMsg: '',
				pushStatus: '',
				validStatus: '',
				pushTime: '',
				checkTime: '',
				isCheck: '',
				lastCheckTime: '',
				isLastCheck: '',
				validTime: '',
				needfulTime: '',
				classRecord: [],
				passPeriod: 0,
				invalidPeriod: 0,
				periodTotal: 0
			},
			part4: {
				pushMsg: '',
				pushStatus: '',
				validStatus: '',
				pushTime: '',
				checkTime: '',
				isCheck: '',
				lastCheckTime: '',
				isLastCheck: '',
				validTime: '',
				needfulTime: '',
				classRecord: [],
				passPeriod: 0,
				invalidPeriod: 0,
				periodTotal: 0
			}
		}
	},
	created() {
		let subject = this.$route.query.subject;
		this.getUploadedPeriodInfo(subject);
		this.activeName = subject;
	},
	methods: {
		onClickLeft() {
			this.$router.back();
		},
		onClickTab(name, title) {
			console.log(title);
			this.getUploadedPeriodInfo(name);
		},
		showMsg(o) {
			Toast.fail(o.deductionreson);
		},
		getUploadedPeriodInfo(subject) {
			this.showLoading = true;
			getUploadedPeriodInfoGD(subject).then(res => {
				if (subject === '1') {
					this.part1 = res;
				} else if (subject === '4') {
					this.part4 = res;
				}
				this.showLoading = false;
			}).catch(() => {
				this.showLoading = false;
			});
		},
		checkValidStatusFormatter (checkVal) {
			if (checkVal === 1) {
				return '初审'
			} else if (checkVal === 2) {
				return '复审'
			} else if (checkVal === 3) {
				return '终审'
			} else {
				return '--'
			}
		},
	}
}
</script>

<style scoped lang="less">
#navbar {
	background-color: #ff8506;
}
#navbar /deep/ .van-ellipsis {
	color: #fff !important;
}
#navbar /deep/ .van-nav-bar__text {
	color: #fff !important;
}
#navbar /deep/ .van-icon {
	color: #fff !important;
}
.form-area {
	margin-top: 46px;
}
.title {
	margin: 0;
	padding: 16px 16px 16px;
	color: rgba(69, 90, 100, 0.6);
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
}
.total-num {
	font-weight: bold;
	font-family: Arial, Helvetica, sans-serif;
	color: #E10900;
}
.van-cell-group__title {
	background-color: #f5f5f5;
}
</style>
